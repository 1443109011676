.card-plain {
  background: transparent;
  box-shadow: none;

  .card-header,
  .card-footer {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
  }

  &:not(.card-subcategories).card-body {
      padding-left: 0;
      padding-right: 0;
  }
}

.number-card {
  .card-header h2{
    font-size: 1.2rem;
    margin-bottom: 0.1em;
  }
  .card-body {
    font-size: 2em;
    color: #fff;
    padding-top: 0;
  }
}
